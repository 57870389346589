import React from "react";
import { Helmet } from "react-helmet";
import Navigation from "../components/Navigation";
import HeroImage from "../components/HeroImage";
import VulkanizerCard from "../components/VulkanizerCard";
import WorkTime from "../components/WorkTime";
import Contacts from "../components/Contacts";
import Footer from "../components/Footer";

import { Container, Row, Col } from "react-bootstrap";
import phonecall from "../images/phone-call.svg";

import vulkanizerImg from "../images/vulkanizer.jpg";
import vulkanizerLogo from "../images/vulkanizer-icon.svg";

const VulkanizerScreen = () => {
  return (
    <>
      <Helmet>
        <title>Avtoservis Strajnar - Vulkanizer</title>
      </Helmet>
      <Navigation />
      <HeroImage image={vulkanizerImg} />

      <Container>
        <a href="tel:041645946">
          <img src={phonecall} alt="About Icon" className="phonecall" />
        </a>
        <div className="main-section">
          <div className="flex-row header-left">
            <img
              src={vulkanizerLogo}
              alt="Vulkanizer Icon"
              className="main-logo"
            />
            <h3 className="main-header">VULKANIZERSTVO</h3>
          </div>
          <Row>
            <Col xl={9}>
              <VulkanizerCard />
            </Col>
            <Col xl={3}>
              <WorkTime />
            </Col>
          </Row>
        </div>
      </Container>

      <Contacts />
      <Footer />
    </>
  );
};

export default VulkanizerScreen;
