import React from "react";

const FooterBottom = () => {
  return (
    <footer className="footer">
      <p className="footer-text">
        Avtorske pravice &copy; 2020 Avtoservis Strajnar. Vse pravice pridržane.
        Izdelava spletne strani Raw Studio
      </p>
    </footer>
  );
};

export default FooterBottom;
