import React from "react";
import { Helmet } from "react-helmet";
import Navigation from "../components/Navigation";
import Hero from "../components/Hero";
import Card from "../components/Card";
import Tradition from "../components/Tradition";
import Contacts from "../components/Contacts";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

import avtomehanik from "../images/servis.jpg";
import vulkanizer from "../images/vulkanizer.jpg";
import kleparstvo from "../images/image0.jpeg";
import izpuh from "../images/izpuh.jpg";
import klima from "../images/klima.jpg";
import avtohiša from "../images/avtohiša.png";

import { Container } from "react-bootstrap";
import phonecall from "../images/phone-call.svg";

const HomeScreen = () => {
  return (
    <>
      <Helmet>
        <title>Avtoservis Strajnar - Domov</title>
      </Helmet>
      <Navigation />
      <Hero />

      
      <Container>

      <a href="tel:041645946">
      <img 
      src={phonecall} 
      alt="About Icon"
       className="phonecall"
        />
        </a>

        <div className="card-grid">
          <Link to="/avtoservis">
            <Card title={"AVTOMEHANIČNE \n STORITVE"} bgImage={avtomehanik} />
          </Link>
          <Link to="/vulkanizerstvo">
            <Card title={"VULKANIZERSKE \n STORITVE"} bgImage={vulkanizer} />
          </Link>
          <Link to="/kleparstvo">
            <Card
              title={"AVTOKLEPARSTVO \n & AVTOLIČARSTVO"}
              bgImage={kleparstvo}
            />
          </Link>
          <Link to="/izpuh">
            <Card title={`IZPUŠNI \n SISTEMI`} bgImage={izpuh} />
          </Link>
          <Link to="/klima">
            <Card title={"KLIMATSKA \n NAPRAVA"} bgImage={klima} />
          </Link>
          <Link to="/o_nas">
            <Card title={"OBIŠČITE \n NAS"} bgImage={avtohiša} />
          </Link>
        </div>
      </Container>
      <Tradition />
      <Contacts />
      <Footer />
    </>
  );
};

export default HomeScreen;
