import React from "react";

const KleparstvoCard = () => {
  return (
    <div className="vulkanizer-card">
      <ul>
        <li>
          <span></span> kvalitetno popravilo karamboliranih ter poškodovanih
          vozil
        </li>
        <li>
          <span></span> popravilo plastike
        </li>
        <li>
          <span></span> menjava stekla na vozilu
        </li>
      </ul>
    
    </div>
  );
};

export default KleparstvoCard;
