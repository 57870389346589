import React from "react";

const TopNav = () => {
  return (
    <div className="top-nav">
      <p className="top-text">Več kot 30 let tradicije</p>
    </div>
  );
};

export default TopNav;
