import React from "react";

const VulkanizerCard = () => {
  return (
    <div className="vulkanizer-card">
      <p className="main-text">Naša storitev vulkanizerstva zajema:</p>
      <ul>
        <li>
          <span></span> menjavo
        </li>
        <li>
          <span></span> uravnoteženje/centriranje
        </li>
        <li>
          <span></span> krpanje
        </li>
        <li>
          <span></span> sezonsko skaldiščenje
        </li>
        <li>
          <span></span> nakup novih pnevmatik
        </li>
      </ul>
      <p className="main-text">
        Po dogovoru vam nudimo tudi optično nastavitev podvozja in popravilo
        aluminijastih platišč.
      </p>
    </div>
  );
};

export default VulkanizerCard;
