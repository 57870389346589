import React from "react";

const KlimaCard = () => {
  return (
    <div className="vulkanizer-card">
      <p className="main-text">
        Ponujamo vam <strong>servis</strong> in <strong>polnjenje</strong>{" "}
        klimatskih naprav. Sem spada vakumiranje hladilnega sistema, zamenjava
        olja v kompresorju in zamenjava hladilnega plina.
      </p>

      <p className="main-text">
        Za pravilno delovanje priporočamo kontrolo klimatske naprave enkrat na
        leto.
      </p>
    </div>
  );
};

export default KlimaCard;
