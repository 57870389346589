import React from "react";
import mobileIcon from "../images/telefon-white.svg";

const WorkTime = () => {
  return (
    <div className="work-time">
      <p className="work-main">DELOVNI ČAS:</p>
      <p className="work">ODPRTO</p>
      <p className="work-main">PON - PET: 7:00 - 16:00</p>
      <p className="work">ZAPRTO (malica)</p>
      <p className="work-main">PON - PET: 12:00 - 13:00</p>

      <span className="mobile-red">
        <img src={mobileIcon} alt="mobile icon" className="mobile-icon" />
        <span className="mobile-red-text">
          <a className="telefonska" href="tel:041 645 946">
            041 645 946
          </a>
        </span>
      </span>
    </div>
  );
};

export default WorkTime;
