import React from "react";
import { Helmet } from "react-helmet";
import Navigation from "../components/Navigation";
import HeroImage from "../components/HeroImage";
import Contacts from "../components/Contacts";
import Footer from "../components/Footer";
import WorkTime from "../components/WorkTime";

import ServisCard from "../components/ServisCard";
import phonecall from "../images/phone-call.svg";
import { Container, Row, Col } from "react-bootstrap";

import servisImg from "../images/imgsrvs.jpg";
import servisLogo from "../images/servis-icon.svg";

const ServisScreen = () => {
  return (
    <>
      <Helmet>
        <title>Avtoservis Strajnar - Vulkanizer</title>
      </Helmet>
      <Navigation />
      <HeroImage image={servisImg} />

      <Container>
        <a href="tel:041645946">
          <img src={phonecall} alt="About Icon" className="phonecall" />
        </a>
        <div className="main-section">
          <div className="flex-row header-left">
            <img src={servisLogo} alt="Servis Icon" className="main-logo" />
            <h3 className="main-header">AVTOSERVIS</h3>
          </div>
          <Row>
            <Col xl={9}>
              <ServisCard />
            </Col>
            <Col xl={3}>
              <WorkTime />
            </Col>
          </Row>
        </div>
      </Container>

      <Contacts />
      <Footer />
    </>
  );
};

export default ServisScreen;
