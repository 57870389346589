import React from "react";

const ServisCard = () => {
  return (
    <div className="vulkanizer-card">
      <p className="main-text">
        Za manjša popravila predhodno naročanje ni potrebno, saj vam okvare
        hitro in strokovno odpravimo v najkrajšem možnem času. Za servis se je
        priporočljivo predhodno naročiti, saj skušamo vsa dela opraviti v istem
        dnevu.
      </p>
      <p className="main-text">
        Pri servisih in menjavah so vam na voljo originalni rezervni deli in
        cenejši deli, ki so kakovostno enakovredni originalnim.
      </p>
      <p className="main-text">
        Nudimo vam tudi elektronsko testiranje vašega vozila in{" "}
        <strong>pripravo na tehnični pregled.</strong>
      </p>
    </div>
  );
};

export default ServisCard;
