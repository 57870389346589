import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Contacts = () => {
  return (
    <div className="contacts">
      <Container>
        <Row>
          <Col xs={12} md={4} lg={4} className="contact">
            <p className="contact-text">AVTOSERVIS Strajnar Luka s.p.</p>
            <p className="contact-text">KAVADARSKA CESTA 21</p>
            <p className="contact-text">1000 Ljubljana</p>
          </Col>

          <Col xs={12} md={4} lg={4} className="contacts-middle contact">
            <p className="contact-text">
              <a href="tel:041 - 645 - 946">MOB.: 041 - 645 - 946</a>
            </p>
            <p className="contact-text">
              <a href="tel:01 507 26 39">TEL.: 01/ 507 26 39</a>
            </p>
            <p className="contact-text">
              <a href="mailto: info@servisstrajnar.si">
                MAIL: info@servisstrajnar.si
              </a>
            </p>
          </Col>

          <Col xs={12} md={4} lg={4} className="contacts-right contact">
            <p className="contact-text contact-text-bold">DELOVNI ČAS:</p>
            <p className="contact-text">PON - PET: 7:00 - 16:00</p>
            <p className="contact-text">ZAPRTO(malica): 12:00 - 13:00</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contacts;
