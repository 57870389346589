import React from "react";
import arrow from "../images/more-arrow.svg";

const Card = ({ title, bgImage }) => {
  return (
    <div
      className="card"
      style={{
        background: `url(${bgImage}) no-repeat center center/cover`,
        border: "none",
      }}
    >
      <h2 className="card-title">{title}</h2>
      <img src={arrow} alt="Find more..." className="arrow" />
    </div>
  );
};

export default Card;
