import React from "react";

const IzpuhCard = () => {
  return (
    <div className="vulkanizer-card">
      <p className="main-text">Storitve vključujejo menjavo:</p>
      <ul>
        <li>
          <span></span> katalizatorjev
        </li>
        <li>
          <span></span> gibljivih cevi
        </li>
        <li>
          <span></span> kolektorjev
        </li>
        <li>
          <span></span> izpušnih loncev
        </li>
        <li>
          <span></span> varjenje izpušnega sistema (v kolikor je to mogoče)
        </li>
      </ul>
    </div>
  );
};

export default IzpuhCard;
