import React from "react";
import { Container } from "react-bootstrap";

import more from "../images/more-arrow.svg";
import avtohisa from "../images/68AC5AFC-0E0F-4318-8834-482ED107DAF2-AEA5A4BF-6F29-4D3F-A4B0-057CE70E8967.JPG";
import { Link } from "react-router-dom";

const Tradition = () => {
  return (
    <>
      <div className="tradition">
        <Container>
          <h4 className="tradition-header">
            AVTOSERVIS IMA ŽE VEČ KOT 30-LETNO TRADICIJO
          </h4>
          <p className="tradition-text">
            V začetku razvoja podjetja smo se zavoljo strokovnosti in lažjega
            spremljanja avtomobilske tehnike posvetili znamki vozil Alfa Romeo
            in z njo tesno povezani znamki Fiat in Lancia. Kasneje pa smo se z
            vsem pridobljenim znanjem in izkušnjami posvetili vsem avtomobilskim
            znamkam.
          </p>
          <span className="tradition-more">
            <Link to="/o_nas">
              <img
                src={more}
                alt="Tradition More..."
                className="tradition-more-icon"
              />
            </Link>
          </span>
        </Container>
        <img
          src={avtohisa}
          alt="Avtoservis Strajnar"
          className="tradition-image"
        />
      </div>
    </>
  );
};

export default Tradition;
