import React from "react";

const AboutCard = () => {
  return (
    <div className="vulkanizer-card">
      <p className="main-text">
        Avtoservis ima že več kot 30-letno tradicijo. V začetku razvoja podjetja
        smo se zavoljo strokovnosti in lažjega spremljanja avtomobilske tehnike
        posvetili znamki vozil Alfa Romeo in z njo tesno povezani znamki Fiat in
        Lancia. Kasneje pa smo se z vsemi pridobljenim znanjem in izkušnjami
        posvetili <strong>vsem avtomobilskim znamkam.</strong>
      </p>
      <p className="main-text">
        <span className="bigger">
          <strong>Naša ponudba</strong>
        </span>{" "}
        obsega avtomehanične in vulkanizerske storitve, popravilo in polnjenje
        klimatskih naprav, zamenjavo izpušnih sistemov, ter avtokleparske in
        avtoličarske storitve. Za naše stranke organiziramo tudi avtovleko.
      </p>
      <p className="main-text">
        <span className="bigger">
          <strong>Servis se nahaja</strong>
        </span>{" "}
        v neposredni bližini Avto šole AMD Zvezda. Do nas pridete, če se iz
        smeri center pripeljete po Vodnikovi cesti mimo tržnice v Kosezah in
        pred ljubljansko severno obvoznico zavijete desno proti Celovški cesti.
        Čez približno 100 metrov zavijete še dvakrat desno in ste na našem
        parkirišču.
      </p>
    </div>
  );
};

export default AboutCard;
