import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import HomeScreen from "./screens/HomeScreen";
import ServisScreen from "./screens/ServisScreen";
import VulkanizerScreen from "./screens/VulkanizerScreen";
import KleparScreen from "./screens/KleparScreen";
import IzpuhScreen from "./screens/IzpuhScreen";
import KlimaScreen from "./screens/KlimaScreen";
import AboutScreen from "./screens/AboutScreen";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/avtoservis">
            <ServisScreen />
          </Route>
          <Route path="/vulkanizerstvo">
            <VulkanizerScreen />
          </Route>
          <Route path="/kleparstvo">
            <KleparScreen />
          </Route>
          <Route path="/izpuh">
            <IzpuhScreen />
          </Route>
          <Route path="/klima">
            <KlimaScreen />
          </Route>
          <Route path="/o_nas">
            <AboutScreen />
          </Route>
          <Route path="/">
            <HomeScreen />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
