import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const MapCard = (props) => {
  return (
    <div className="map-card">
      <Map
        google={props.google}
        zoom={16}
        initialCenter={{
          lat: 46.076620,
          lng: 14.474990,
        }}
        className="mapa"
      >
        <Marker
          name={"Avtohiša Strajnar"}
          position={{ lat: 46.076620, lng: 14.474990 }}
        />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(MapCard);


