import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../images/Logo01.png";
import logoMobile from "../images/Logo02.png";
import mobile from "../images/telefon.svg";
import TopNav from "./TopNav";
import { Link } from "react-router-dom";

const Navigation = () => {
  const [dropdown, setDropdown] = useState(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const onClick = () => {
    setDropdown(!dropdown);
  };

  return (
    <>
      <TopNav />
      <Navbar collapseOnSelect expand="lg" className="navbar">
        <Navbar.Brand>
          <Link to="/">
            
              <img
                alt="Avtoservis Strajnar"
                src={logo}
                className="d-inline-block align-top logo"
              />
           
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link eventKey="1">
              <Link to="/">DOMOV</Link>
            </Nav.Link>
            <Nav.Link eventKey="2">
              <Link to="/o_nas">O PODJETJU</Link>
            </Nav.Link>
            <NavDropdown
              title="STORITVE"
              id="basic-nav-dropdown"
              className="hoverItem"
              show={dropdown}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={onClick}
            >
              <NavDropdown.Item eventKey="3">
                <Link className="dropdown-item" to="/avtoservis">
                  AVTOSERVIS
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4">
                <Link className="dropdown-item" to="/vulkanizerstvo">
                  VULKANIZERSTVO
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="5">
                <Link className="dropdown-item" to="/kleparstvo">
                  AVTOKLEPARSTVO
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="6">
                <Link className="dropdown-item" to="/izpuh">
                  IZPUŠNI SISTEM
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="7">
                <Link className="dropdown-item" to="/klima">
                  KLIMA
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
            <span className="mobile">
              <img src={mobile} alt="mobile-icon" className="mobile-icon" />
              <span className="mobile-number">
                <a href="tel:041 645 946">041 645 946</a>
              </span>
            </span>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Navigation;