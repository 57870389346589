import React from "react";
import { Helmet } from "react-helmet";
import Navigation from "../components/Navigation";
import HeroImage from "../components/HeroImage";
import Contacts from "../components/Contacts";
import Footer from "../components/Footer";
import WorkTime from "../components/WorkTime";

import IzpuhCard from "../components/IzpuhCard";
import phonecall from "../images/phone-call.svg";

import { Container, Row, Col } from "react-bootstrap";

import izpuhImg from "../images/izpuh.jpg";
import IzpuhLogo from "../images/izpuh-icon.svg";

const IzpuhScreen = () => {
  return (
    <>
      <Helmet>
        <title>Avtoservis Strajnar - Vulkanizer</title>
      </Helmet>
      <Navigation />
      <HeroImage image={izpuhImg} />

      <Container>
        <a href="tel:041645946">
          <img src={phonecall} alt="About Icon" className="phonecall" />
        </a>
        <div className="main-section">
          <div className="flex-row header-left">
            <img src={IzpuhLogo} alt="Izpuh Icon" className="main-logo" />
            <h3 className="main-header">Izpušni sistemi</h3>
          </div>
          <Row>
            <Col xl={9}>
              <IzpuhCard />
            </Col>
            <Col xl={3}>
              <WorkTime />
            </Col>
          </Row>
        </div>
      </Container>

      <Contacts />
      <Footer />
    </>
  );
};

export default IzpuhScreen;
