import React from "react";
import { Carousel } from "react-bootstrap";
import img1 from "../images/0DA65DFD-1D69-45C5-B4FB-25F822478D7B-3FEF8096-1F49-4C6A-927E-B1B0A865E426.JPG";
import img2 from "../images/alexander-schimmeck-A9U3dYkOpjk-unsplash.jpg";
import img3 from "../images/daniele-levis-pelusi-ZLJg4Md5HiY-unsplash.jpg";
import img4 from "../images/slika.JPG";

const Hero = () => {
  return (
    <Carousel className="hero">
      <Carousel.Item>
        <img className="hero-image" src={img1} alt="First slide" />
      </Carousel.Item>
 
    </Carousel>
  );
};

export default Hero;
